export default class SmoothScroll {
  constructor() {
    // this.offset = 100;
    this.offset = 0;
    this.scrollSpeed = 50;
    this.pageHash = window.location.hash;
    this.pageDir = window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/') + 1);
    this.init();
  }

  init() {
    this.updateHashLinks();
    window.addEventListener('load', () => {
      this.addClickListeners();
      if (window.performance.navigation.type === 0 && this.pageHash) {
        setTimeout(() => this.scrollToHash(), 200);
      }
    });
  }

  updateHashLinks() {
    const links = document.querySelectorAll('a[href*="#"]');
    links.forEach((element) => {
      const href = element.getAttribute('href');
      const path = href.replace(window.location.origin, '');
      const dir = path.substring(0, path.lastIndexOf('/') + 1);
      const hash = href.match(/#.+$/);
      if (hash && this.pageDir === dir) {
        element.setAttribute('href', hash);
      }
    });
  }

  addClickListeners() {
    const links = document.querySelectorAll('a[href^="#"]');
    links.forEach((link) => {
      link.addEventListener('click', (event) => {
        event.preventDefault();
        const href = link.getAttribute('href');
        const target = href === '#' || href === '' ? document.documentElement : document.querySelector(href);
        const position = target.getBoundingClientRect().top + window.scrollY - document.querySelector('.header').offsetHeight - this.offset;
        window.scroll({ top: position, left: 0, behavior: 'smooth' });
      });
    });
  }

  scrollToHash() {
    const target = document.querySelector(this.pageHash);
    if (target) {
      const position = target.getBoundingClientRect().top + window.scrollY - document.querySelector('.header').offsetHeight - this.offset;
      window.scroll({ top: position, left: 0, behavior: 'smooth' });
    }
  }

}
