import Common from './pages/common.js';
import Header from './modules/header';
import Top from './pages/top.js';

class App {
	constructor() {
		this.routes();
	}

	routes() {
		new Common();

		this.header = new Header();
		if (this.header.element) this.header.init();

		this.top = new Top();
		if (this.top.element) this.top.init();

	}
}

window.App = new App();
