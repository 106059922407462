import SmoothScroll from '../modules/smoothscroll.js';
import Modal from '../modules/modal.js';
import Accordion from '../modules/accordion.js';

export default class Common {
  constructor() {
    this.init();
  }

  init() {
    new SmoothScroll();
    new Modal();
    new Accordion();
    this.currentActiveLink();
  }
  

  /**
   * フッター上のナビゲーションで使用
   */
  currentActiveLink(){
    const currentUrl = new URL(window.location.href);
    const currentDomain = currentUrl.origin;
    let currentPath = currentUrl.pathname;

    // 連続する//,index.html　のパターンを削除
    currentPath = currentPath.replace(/\/\/+/g, '/');
    currentPath = currentPath.replace(/\/index\.html$/, '/');

    const links = document.querySelectorAll('.bottom-nav a');

    links.forEach(function(link) {
      const linkUrl = new URL(link.href);

      if (linkUrl.origin === currentDomain && linkUrl.pathname === currentPath) {
        link.classList.add('is-current');
        link.setAttribute('aria-current', 'page');
        link.setAttribute('tabindex', '-1');
      }
    });
  }

}
