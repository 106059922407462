import { breakpoint } from '../helpers/helpers.js';


export default class Header {
  constructor() {
    this.body = document.querySelector('body');
    this.element = document.querySelector('header');
    this.burger = document.querySelector('#burger');
    this.menuMainSp = document.querySelector('#menuMainSp');
    this.button = document.querySelectorAll('.header_nav_box');
    this.headLogo = document.querySelector('.headLogo img');
    this.breakpoint = breakpoint;

    //dropdown
    this.menuMainItems = document.querySelectorAll('.menuMain li');

    if (this.body.classList.contains('top')) {
      this.scrollChange();
    }

    this.dropdown();


  }

  init() {

    this.burger.addEventListener('click', () => {
      this.body.classList.toggle("spMenuMode_is_opened");
      this.element.classList.toggle("spMenuMode_is_opened");
      this.burger.classList.toggle('spMenuMode_is_opened');
      this.menuMainSp.classList.toggle('spMenuMode_is_opened');

      if (!this.burger.classList.contains('spMenuMode_is_opened')) {
        for (let i = 0; i < this.button.length; i++) {
          this.button[i].classList.remove('is_active');
        }
      }

    })

    for (let i = 0; i < this.button.length; i++) {
      this.button[i].addEventListener("click", function () {
        this.classList.toggle('is_active');
      });
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth > 768) {
        if (this.burger.classList.contains('spMenuMode_is_opened')) {
          this.body.classList.remove("spMenuMode_is_opened");
          this.element.classList.toggle("spMenuMode_is_opened");
          this.burger.classList.remove('spMenuMode_is_opened');
          this.menuMainSp.classList.remove('spMenuMode_is_opened');
          for (let i = 0; i < this.button.length; i++) {
            this.button[i].classList.remove('is_active');
          }
        }
      }
    })

  }

  scrollChange() {
    const header = document.querySelector(".header");
    const transformTrigger = document.querySelector(".js-header-transfrom");
    if (!transformTrigger) return;
    let rootMargin = "-100px 0px 0px 0px";

    let obs;

    // IntersectionObserverのコールバック関数
    const headerIntersectionCallback = (entries) => {

      if (!entries[0].isIntersecting) {
        header.classList.remove("is-transparent");
        this.headLogo.src = "/biz/assets/images/common/pasco_logo_b.png";
      } else {
        header.classList.add("is-transparent");
        this.headLogo.src = "/biz/assets/images/common/pasco_logo_w.png";
      }
    };

    // IntersectionObserver初期化
    const initIntersectionObserver = () => {
      obs = new IntersectionObserver(headerIntersectionCallback, { rootMargin: rootMargin });
      obs.observe(transformTrigger);
    };

    // pc,spで rootMargin を変更
    const updateRootMargin = () => {
      const breakpoint = window.matchMedia(`(min-width: ${this.breakpoint}px)`);

      const breakpointHandler = (e) => {
        if (e.matches) {
          rootMargin = "-130px 0px 0px 0px"; // pcの rootMargin
        } else {
          rootMargin = "-70px 0px 0px 0px"; // spの　rootMargin
        }

        obs.disconnect();
        initIntersectionObserver();
      };

      breakpointHandler(breakpoint);
      breakpoint.addEventListener("change", breakpointHandler);
    };

    initIntersectionObserver();
    updateRootMargin();
  }

  dropdown() {
    this.activateCategoryItem();

    this.navHasSubs = [...document.querySelectorAll('.tab-menu .tab-item')];
    [...this.navHasSubs].forEach(item => {
      item.addEventListener('click', this.tabSwitch.bind(this));
    });

    const megamenuClose = document.querySelectorAll('.megamenu-wrap__close');

    megamenuClose.forEach(item => {
      item.addEventListener('click', this.closeMenu.bind(this));
    });

    window.addEventListener('scroll', this.closeMenu.bind(this));
  }

  closeMenu() {
    const dropdown = document.querySelector('.dropdown-wrap');
    if (dropdown) {
      dropdown.style.display = 'none';
    }
    this.menuMainItems.forEach(item => item.classList.remove('is-active'));
  }

  tabSwitch(e) {
    const tabList = e.currentTarget.closest('.tab-menu');
    const tabItems = tabList.querySelectorAll('.tab-item');
    const tabTargetData = tabList.dataset.tabParent;
    const tabPanelList = document.querySelector(`#${tabTargetData}`);
    if (tabTargetData === 'header-tab') {
      tabPanelList.style.display = 'block';
    }

    const tabPanelItems = [...tabPanelList.children];
    tabItems.forEach((item) => {
      item.classList.remove("is-active");
    });
    tabPanelItems.forEach((content) => {
      content.classList.remove("is-active");
    });
    e.currentTarget.classList.add('is-active');

    const aryTabs = Array.from(tabItems);
    const index = aryTabs.indexOf(e.currentTarget);

    tabPanelItems[index].classList.add("is-active");
  }

  activateCategoryItem() {
    const categoryNav = document.querySelector('main .category-nav');
    if (!categoryNav) return;
    const categoryNavItems = categoryNav.querySelectorAll('.category-nav__item');

    for (let item of categoryNavItems) {
      let currentItem = item.querySelector('.is_current');
      if (currentItem) {
        const currentItemData = currentItem.dataset.navCategory;
        if(currentItemData){
          const dropdownCategoryItems = document.querySelectorAll('.dropdown-wrap .category-nav__item');
          const specificItem = Array.from(dropdownCategoryItems).find(item => item.getAttribute('data-tab-category') === currentItemData);
          if(specificItem){
            const tabCategory = specificItem.dataset.tabCategory;
            if(tabCategory !== "facility-reservation" && tabCategory !== "wagamachiguide"){
              const currtentTabParent = specificItem.closest('.category-nav__list');
              const currentTabItems = currtentTabParent.querySelectorAll('.category-nav__item.tab-item');
              currentTabItems.forEach((item) => {
                item.classList.remove('is-active');
              });
              specificItem.classList.add('is-active');

              const currentMegamenus = specificItem.closest('.dropdown').querySelectorAll('.megamenu');
              const aryTabs = Array.from(currentTabItems);
              const index = aryTabs.indexOf(specificItem);

              currentMegamenus.forEach((item) => {
                item.classList.remove("is-active");
              });
              currentMegamenus[index].classList.add("is-active");
            }
          }
        }
      }
    }

  }


}
