export default class Accordion {
    constructor() {
  
      const menu = document.querySelectorAll(".js-accordion-trigger");
  
      function toggle() {
        const content = this.nextElementSibling;
        this.classList.toggle("is_active");
        content.classList.toggle("is_open");
      }
  
      for (let i = 0; i < menu.length; i++) {
        menu[i].addEventListener("click", toggle);
      }
    }
  
  }
  
  