import $ from 'jquery';
import LightBox2 from 'lightbox2';

export default class Modal {
  constructor() {

    LightBox2.option({
      'resizeDuration': 200,
      'wrapAround': true
    })

  }

}
