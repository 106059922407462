import $ from 'jquery';
import Swiper from 'swiper/bundle';
import { Navigation, Pagination, Autoplay } from 'swiper';
import Rellax from 'rellax';

export default class Top {

  constructor() {
    this.element = document.getElementById('top');
    if(! this.element) return;

    Swiper.use([Navigation, Pagination, Autoplay]);

    // Swiper TOP Topics
    const topTopicsSwiper = new Swiper('.topTopics .swiper', {
      loop: true,
      loopAdditionalSlides: 1,
      watchSlidesProgress: true,
      slidesPerView: 1.5,
      grabCursor: true,
      threshold:4,
      navigation: {
        nextEl: '.topTopics .swiper-button-next',
        prevEl: '.topTopics .swiper-button-prev',
      },
      breakpoints: {
        600: {
          slidesPerView: 2,
        },
        1025: {
          slidesPerView: 2.947,
        }
      },
    });

    // Swiper TOP Software
    const topSoftwareSwiper = new Swiper('.topSoftware .swiper', {
      loop: true,
      loopAdditionalSlides: 1,
      watchSlidesProgress: true,
      slidesPerView: 1.5,
      grabCursor: true,
      threshold:4,
      navigation: {
        nextEl: '.topSoftware .swiper-button-next',
        prevEl: '.topSoftware .swiper-button-prev',
      },
      breakpoints: {
        600: {
          slidesPerView: 2,
        },
        1025: {
          slidesPerView: 2.947,
        }
      },
    });

    // parallax TOP Business,Survey
    // if (window.matchMedia && window.matchMedia('(max-device-width: 767px)').matches) {
    //   const topBusinessRellax = new Rellax('.js-rellax img' , {
    //     speed: 0,
    //     center: true,
    //     breakpoints:[300,960,1200] //必ず３つ指定する必要がある
    //   });
    // }
    const topBusinessRellax = new Rellax('.js-rellax img' , {
      speed: 0,
      center: true,
      breakpoints:[300,960,1200] //必ず３つ指定する必要がある
    });
  }

  init() {

    // Businessのページ番号の切り替え
    $(function(){
      $(window).on('scroll resize',function(){
        let h =$('.topBusiness-ListWrapper .item').outerHeight();  //対象要素の高さ

        $('.topBusiness-ListWrapper .item').each(function(){
          // var elmTop=$(this).offset().top + 200;     //画面topから対象要素のtopまでの距離
          var elmTop=$(this).offset().top + 0;          //画面topから対象要素のtopまでの距離
          var winHeight=$(window).height();             //1画面分の高さ
          var scrTop=$(window).scrollTop();             //自分がスクロールした量

          // var winHeightharf = winHeight / 4;
          var winHeightharf = winHeight / 2;

          //自分のスクロール量>対象要素の位置-1画面分+要素の高さ →  画面下から出てきたタイミング
          // if(scrTop > elmTop - winHeight + h){
          //   $(this).addClass('active');
          // }else{
          //   $(this).removeClass('active');
          // }

          // →  画面上から1/4のところまで出てきたタイミングでaddClass(01のアンカーのタイミングの合わせる)
          // →  画面上から1/2のところまで出てきたタイミングでaddClass(01のアンカーのタイミングの合わせる)
          if(scrTop > elmTop - winHeightharf){
            $(this).addClass('is-active');
          } else {
            $(this).removeClass('is-active');
          }

        });
      });
    });

  }
}
